import 'css/fonts.css';

import { AppProps } from 'next/app';
import Head from 'next/head';

import { DesignSystemProvider, CalmTheme } from '@calm-web/design-system';

import IntlWrapper from '@/components/layout/IntlWrapper';

const MyApp = ({ Component, pageProps }: AppProps<{}>): JSX.Element => {
	return (
		<>
			<Head>
				<link rel="shortcut icon" type="image/x-icon" href="/_n/favicon.ico" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
			</Head>
			<IntlWrapper>
				<DesignSystemProvider theme={CalmTheme}>
					<Component {...pageProps} />
				</DesignSystemProvider>
			</IntlWrapper>
		</>
	);
};

export default MyApp;
